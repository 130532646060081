<template>
  <div>
    <b-card>
      <!-- title -->
      <div class="d-flex justify-content-between flex-wrap">
        <h2 class="my-auto">
          <!-- {{ $t('evaluations.evaluation_list.title') }} <br /> -->
          Evaluations éffectuées
        </h2>
      </div>
      <hr>
      <!-- filter -->
      <app-collapse class="nopadding mt-2">
        <app-collapse-item :title="$t('user_manage.filter')">
          <!-- options -->
          <b-row>
            <b-col lg="6">
              <!-- recherche par periode -->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group
                  class="col pr-0 pl-0"
                  :label="'Rechercher par période d\'évaluation'"
                >
                  <b-input-group>
                    <DateRangeWidget
                      v-model="filterBy.periode"
                      :range="filterBy.periode"
                      placeholder="Filtrer par date"
                    />
                  </b-input-group>
                </b-form-group>
              </div>
            </b-col>
            <b-col lg="6">
              <!-- nom évaluation  -->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group
                  class="col pr-0 pl-0"
                  input-cols-xl="9"
                  :label="'Nom de l\'évaluation'"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="filterBy.title"
                      type="text"
                      class="form-control"
                      placeholder="Nom de l'établissement"
                    />
                    <b-input-group-append
                      is-text
                      @click="
                        () => {
                          filterBy.title = null;
                        }
                      "
                    >
                      <feather-icon
                        v-b-tooltip.hover.bottom="'Vider le champs!'"
                        icon="XCircleIcon"
                        class="cursor-pointer"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
            </b-col>
            <b-col lg="6">
              <!-- user_id évalué   -->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group
                  class="col pr-0 pl-0"
                  :label="'Auteur de l\'évaluation'"
                >
                  <b-input-group>
                    <v-select
                      v-model="filterBy.user_id"
                      class="w-100"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="fullname"
                      :reduce="(option) => option.id"
                      :options="listOperatorsData"
                      placeholder="Choisissez un établissement. Exemple: Home Bénin"
                    />
                  </b-input-group>
                </b-form-group>
              </div>
            </b-col>
            <b-col lg="6">
              <!-- profile_id-->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group
                  class="col pr-0 pl-0"
                  :label="'Établissement de l\'auteur de l\'évaluation'"
                >
                  <b-input-group class="w-100">
                    <v-select
                      v-model="filterBy.profile_id"
                      class="w-100"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :reduce="(option) => option.id"
                      :options="listProfilesData"
                      placeholder="Choisissez un établissement."
                    />
                  </b-input-group>
                </b-form-group>
              </div>
            </b-col>
            <b-col lg="6">
              <!-- type_auto_evaluation_id-->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group
                  class="col pr-0 pl-0"
                  :label="'Type de l\'auto évaluation'"
                >
                  <b-input-group class="w-100">
                    <v-select
                      v-model="filterBy.type_auto_evaluation_id"
                      class="w-100"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :reduce="(option) => option.id"
                      :options="typeAutoEvaluationData"
                      placeholder="Choisissez le type d'auto évaluation associé rôle."
                    />
                  </b-input-group>
                </b-form-group>
              </div>
            </b-col>
            <b-col lg="6">
              <!-- secteur_id-->
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group
                  class="col pr-0 pl-0"
                  :label="'Secteur de l\'auto évaluation'"
                >
                  <b-input-group class="w-100">
                    <v-select
                      v-model="filterBy.secteur_id"
                      class="w-100"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :reduce="(option) => option.id"
                      :options="listSectorsData"
                      placeholder="Choisissez le secteur associé."
                    />
                  </b-input-group>
                </b-form-group>
              </div>
            </b-col>
            <b-col lg="12">
              <!-- button -->
              <div class="d-flex flex-row-reverse nopadding mt-1">
                <b-button
                  class="float-right"
                  variant="warning"
                  @click="searchEvaluation()"
                >
                  <feather-icon
                    icon="SearchIcon"
                    class="mr-25"
                  />
                  <span>{{ $t("button.search") }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </app-collapse-item>
      </app-collapse>
    </b-card>

    <!-- <b-card>
      <div style="display: grid; grid-template-columns: 1fr 1fr 1fr">
        <vue-apex-charts
          type="donut"
          height="200"
          :options="donutChartStat.chartOptions"
          :series="donutChartStat.series"
        />
        <vue-apex-charts
          type="donut"
          height="200"
          :options="donutChartStat2.chartOptions"
          :series="donutChartStat2.series"
        />
        <vue-apex-charts
          type="donut"
          height="200"
          :options="donutChartStat3.chartOptions"
          :series="donutChartStat3.series"
        />
      </div>
    </b-card> -->

    <div class="row">
      <b-card
        title="Historique des auto-évaluations"
        class="col-12"
      >
        <b-table
          ref="refFormsTable"
          :items="tableData"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="Aucune auto-évaluation"
          :busy="isLoading"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>
          <template #cell(type)="data">
            <h5>
              <!-- {{ data.item }} -->
              <b-badge
                pill
                :variant="'dark'"
              >{{
                data.item.type_auto_evaluation.type === "evaluation"
                  ? "Évaluation"
                  : "Examen"
              }}</b-badge>
            </h5>
          </template>
          <template #cell(status)="data">
            <h5>
              <b-badge
                pill
                :variant="
                  data.item.status === 'Terminated' ? 'primary' : 'secondary'
                "
              >{{
                data.item.status === "Terminated" ? "Terminé" : "En cours"
              }}</b-badge>
            </h5>
          </template>
          <template #cell(user)="data">
            {{ data.item.user && data.item.user.firstname }}
            {{ data.item.user && data.item.user.lastname }}
          </template>
          <template #cell(date)="data">
            {{ reverseDate(data.item.created_at) }}
          </template>
          <!-- <template #cell(action)="data">
            <b-button
              :disabled="isCreatingNewEvaluation"
              @click="continueEvaluation(data.item.id)"
              variant="primary"
              class="mx-0"
              size="sm"
            >
              <span class="text-nowrap">
                <i class="las la-eye"></i>
              </span>
            </b-button>
          </template> -->
        </b-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardText,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BButton,
  BTable,
  BLink,
  BModal,
  VBModal,
  BBadge,
  BSpinner,
  VBToggle,
  BDropdown,
  BDropdownItem,
  BListGroup,
  BListGroupItem,
  BInputGroupAppend,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { onClickOutside } from '@vueuse/core'

import flatPickr from 'vue-flatpickr-component'
import { mapActions } from 'vuex'
import localstorageService from '@/services/localstorage/localstorage.service'
import utilsService from '@/services/utils/utils.service'

// store modules and vuex utilities
import etablissementsStoreModule from '@/store/etablissements'
import evaluationsStoreModule from '@/store/evaluations'
import usersStoreModule from '@/store/users'
import secteursStoreModule from '@/store/secteurs'

// eslint-disable-next-line import/no-cycle
import {
  registerStoreModule,
  // unregisterStoreModule,
} from '@/helpers/vuex-utils'

import VueApexCharts from 'vue-apexcharts'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DateRangeWidget from '@/components/DateRangeWidget.vue'
import PaginationComponent from '@/components/PaginationComponent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    // ProductsListFilters,
    // UserListAddNew,

    BCard,
    BCardBody,
    BRow,
    BCardText,
    BInputGroup,
    // BCollapse,
    BCol,
    BForm,
    BFormGroup,
    BInputGroupAppend,
    BFormInput,
    VBTooltip,
    BButton,
    BTable,
    BLink,
    BBadge,
    BSpinner,
    BModal,
    vSelect,
    PaginationComponent,
    AppCollapse,
    AppCollapseItem,
    flatPickr,
    DateRangeWidget,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    VueApexCharts,
  },

  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      filterBy: {
        periode: null,
        title: null,
        user_id: null,
        type_auto_evaluation_id: null,
        profile_id: null,
        secteur_id: null,
      },
      tableColumns: [
        {
          key: 'title',
          label: 'Libellé',
          sortable: true,
        },
        {
          key: 'type',
          label: 'Type',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Statut',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Date',
          sortable: true,
        },
        {
          key: 'user',
          label: 'Auteur',
          sortable: true,
        },
        // {
        //   key: "action",
        //   label: "Actions",
        // },
      ],

      tableData: [],
      createdAutoEvalDatas: null,
      typeAutoEvaluationData: [],
      listOperatorsData: [],
      listSectorsData: [],
      isFetchingTypeAutoEval: false,
      isFetchingOperators: false,
      isFetchingSectors: false,
      isFetchingProfiles: false,
      isCreatingNewEvaluation: false,
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      listProfilesData: [],
      listStatus: [],
      listMetiers: [],
      listObjets: [
        { id: '1', title: 'Retard du moyen de transport' },
        { id: '2', title: 'Service client irrespectueux' },
        { id: '3', title: 'Problèmes de réservation' },
        { id: '4', title: 'Guide mal informé' },
        { id: '5', title: 'Itinéraire de visite insatisfaisant' },
        { id: '6', title: 'Guide irrespectueux ou désintéressé.' },
        { id: '7', title: 'Réservation annulée sans préavis' },
        { id: '8', title: 'Problèmes de facturation ou de paiement' },
        { id: '9', title: 'Bruit excessif' },
        { id: '10', title: 'Service lent ou inattentif' },
        { id: '11', title: 'Commande incorrecte' },
        { id: '12', title: "Problèmes d'hygiène ou de salubrité" },
      ],
      showListOfPossibleAutoEval: false,

      // DATA VISUALIZATIONS
      donutChartStat: {
        series: [350, 200],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '12px',
            fontFamily: 'Montserrat',
          },
          colors: ['#3a8e84', '#C0C0C0'],
          dataLabels: {
            enabled: true,
            fontSize: '15px',
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '10px',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '15px',
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '15px',
                    label: 'Auto-évaluations',
                    fontWeight: 'bold',
                    formatter() {
                      return '550'
                    },
                  },
                },
              },
            },
          },
          labels: ['Traitées', 'Non traitées'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '10px',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1rem',
                          color: 'black',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
      donutChartStat2: {
        series: [250, 250],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '12px',
            fontFamily: 'Montserrat',
          },
          colors: ['#cba849', '#C0C0C0'],
          dataLabels: {
            enabled: true,
            fontSize: '15px',
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '10px',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '15px',
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '15px',
                    label: 'Totaux',
                    fontWeight: 'bold',
                    formatter() {
                      return '500'
                    },
                  },
                },
              },
            },
          },
          labels: ['Traitées', 'Non traitées'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '10px',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1rem',
                          color: 'black',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
      donutChartStat3: {
        series: [150, 250],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '12px',
            fontFamily: 'Montserrat',
          },
          colors: ['#cc6565', '#C0C0C0'],
          dataLabels: {
            enabled: true,
            fontSize: '15px',
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '10px',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '15px',
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '15px',
                    label: 'Totaux',
                    fontWeight: 'bold',
                    formatter() {
                      return '400'
                    },
                  },
                },
              },
            },
          },
          labels: ['Traitées', 'Non traitées'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '10px',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1rem',
                          color: 'black',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        // this.loadProfils();
        // this.loadMetiers();
        this.loadAutoEvaluationType()
        this.loadOperateurs()
        this.loadSectors()

        this.searchEvaluation()
      },
    },
    perPage: {
      immediate: true,
      handler(val, old) {
        if (this.tableData && this.tableData[val - 1]) {
          this.tableData = this.customCloneDeep(
            this.tableData.slice(0, val - 1),
          )
        }
      },
    },
    'paginationData.currentPage': {
      handler(val, old) {
        this.searchEvaluation()
      },
    },
    'paginationData.perPage': {
      handler(val, old) {
        this.searchEvaluation()
      },
    },
  },
  mounted() {
    const { listOfPossibleAutoEval } = this.$refs
    onClickOutside(listOfPossibleAutoEval, () => {
      this.showListOfPossibleAutoEval = false
    })
  },

  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStoreModule },
      { path: 'evaluations', module: evaluationsStoreModule },
      { path: 'users', module: usersStoreModule },
      { path: 'secteurs', module: secteursStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    return {
      requiredStoreModules,
    }
  },

  // beforeDestroy() {
  //   unregisterStoreModule(this.requiredStoreModules)
  // },

  methods: {
    ...mapActions('etablissements', {
      action_searchEtablissement: 'searchEtablissement',
      action_getListTypesEtablissement: 'getListTypesEtablissement',
    }),
    ...mapActions('evaluations', {
      action_createEvaluation: 'createEvaluation',
      action_searchEvaluation: 'searchEvaluation',
      action_searchTypeEvaluation: 'searchTypeEvaluation',
    }),
    ...mapActions('users', {
      action_searchUsers: 'searchUsers',
    }),
    ...mapActions('secteurs', {
      action_fetchSecteurs: 'fetchSecteurs',
    }),
    // --------------------------------------------------------------
    // Chargement établissement (profiles)
    async loadProfils() {
      try {
        const response = await this.action_searchEtablissement()
        if (response.status === 200) {
          this.listProfilesData = response.data.data
        }
      } catch (error) {
        console.log('Erreur lors du chargement de la liste des profils', error)
      }
    },
    // Chargement des métiers
    async loadMetiers() {
      try {
        const response = await this.action_getListTypesEtablissement()
        if (response.status === 200) {
          this.listMetiers = response.data.data
        }
      } catch (error) {
        console.log('Erreur lors du chargement de la liste des métiers', error)
      }
    },
    // Chargement type d'auto-evalautions
    loadAutoEvaluationType() {
      this.isFetchingTypeAutoEval = true
      this.action_searchTypeEvaluation({ type: 'evaluation' })
        .then(async response => {
          if (response.status === 200) {
            this.typeAutoEvaluationData = response.data.data || []
            this.isFetchingTypeAutoEval = false
          }
        })
        .catch(err => {
          console.log('err::: ', err)
          this.isFetchingTypeAutoEval = false
        })
    },
    // Chargement opérateurs
    loadOperateurs() {
      this.isFetchingOperators = true
      this.action_searchUsers({ role_id: 4 })
        .then(async response => {
          if (response.status === 200) {
            this.listOperatorsData = response.data.data || []
            this.listOperatorsData = this.listOperatorsData.map(user => ({
              ...user,
              fullname: `${user.lastname} ${user.firstname}`,
            }))
            this.isFetchingOperators = false
          }
        })
        .catch(err => {
          console.log('err::: ', err)
          this.isFetchingOperators = false
        })
    },
    // Chargement secteurs
    loadSectors() {
      this.isFetchingSectors = true
      this.action_fetchSecteurs()
        .then(async response => {
          if (response.status === 200) {
            this.listSectorsData = response.data.data || []
            this.isFetchingSectors = false
          }
        })
        .catch(err => {
          console.log('err::: ', err)
          this.isFetchingSectors = false
        })
    },
    // Recherche de auto évaluation
    searchEvaluation() {
      try {
        this.isLoading = true
        const params = {
          ...this.filterBy,
          page: this.paginationData.currentPage - 1,
          perPage: this.paginationData.perPage,
        }
        // console.log('searchEvaluation params::: ⭕️', params);
        // return;
        this.action_searchEvaluation(params)
          .then(async response => {
            if (response.status === 200) {
              this.tableData = response.data.data
              // console.log("this.tableData ::: ⭕️", this.tableData);
              this.isLoading = false
              // --------------------------------------------------------------
              this.paginationData.total = response.data.meta.total || 0
              if (this.paginationData.metaData.from === 0) {
                this.paginationData.metaData.from += 1
              } else {
                this.paginationData.metaData.from = this.paginationData.perPage
                    * this.paginationData.currentPage
                  - this.paginationData.perPage
              }
              this.paginationData.metaData.to = this.paginationData.perPage * this.paginationData.currentPage
                - this.paginationData.perPage
                + response.data.meta.total
              // --------------------------------------------------------------
            }
          })
          .catch(error => {
            this.isLoading = false
            console.error(
              'Erreur lors de la recherche des auto-évaluations.',
              error,
            )
          })
      } catch (error) {
        this.isLoading = false
        console.error(
          'Erreur lors de la recherche des auto-évaluations.',
          error,
        )
      }
    },
    // Continuer une auto-évaluation
    continueEvaluation(evaluationId) {
      this.$router.push({
        name: 'evaluation-procedure',
        params: { id: evaluationId },
      })
    },
    // Changement de page
    onPaginationChange(val) {
      this.paginationData.currentPage = val.currentPage
      this.paginationData.perPage = val.perPage
    },
  },
}
</script>
<style>
::v-deep .dropdown-menu {
  padding: 0 !important;
}
::v-deep .dropdown-item {
  padding: 0;
  margin: 0;
}
.typeEvaluation {
  text-decoration: none;
  color: #0e6258;
}
.typeEvaluation:hover {
  color: #fff;
  background-color: #0e6258;
}

.custom-list-group-2 {
  max-height: 200px;
  overflow-y: auto;
  /* z-index: 100; */
}
.custom-list-group {
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 45px;
  /* z-index: 100; */
}
.custom-list-group:hover .list-item:hover {
  background-color: #0e6258;
  color: #fff;
}
.custom-list-group-2:hover .list-item:hover {
  background-color: #0e6258;
  color: #fff;
}
.list-item {
  cursor: pointer;
}
.new-autoevalbox {
  position: relative;
}
.auto-evaluation-sections {
  display: grid;
  column-gap: 2em;
}
/* MEDIA QUERIES */
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
  .auto-evaluation-sections {
    grid-template-columns: 350px 1fr;
    /* grid-template-columns: 350px 1fr 350px; */
  }
}
@media (min-width: 1100px) {
}
@media (min-width: 1400px) {
}
</style>
